import React, { lazy } from 'react';

const UploadPicture = lazy(() => import('../containers/upload-picture'));
const Welcome = lazy(() => import('../containers/welcome'));

// const Checkout = lazy(() => import('../containers/checkout'));

export const publicRoutes = [
  { path: '/', exact: true, component: () => <Welcome />, parent: null },
  {
    path: '/order',
    exact: true,
    component: () => <UploadPicture />,
    parent: null,
  },
];

// export const privateRoutes = [
//   {
//     path: '/pvt',
//     exact: true,
//     component: () => <div />,
//     parent: null,
//   },
// ];

// export const routesByParent = (parent: string | null) => privateRoutes.filter((route) => route.parent === parent);
