import React, { Suspense } from 'react';
import 'typeface-rubik';
import { Switch, BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
// import { HttpLink } from 'apollo-link-http';
import { createUploadLink } from 'apollo-upload-client';
import FullScreenLoading from './components/loading/fullscreen-loading';
import { publicRoutes } from './routes';
import Layout from './containers/layout';
import theme from './components/theme';
import config from './config';

function App() {
  const cache = new InMemoryCache();
  const link = createUploadLink({
    uri: config.app.graphQLEndpoint,
  });

  const client = new ApolloClient({
    cache: cache,
    link: link,
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<FullScreenLoading />}>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}>
            <Router>
              <Switch>
                <Layout>
                  {publicRoutes.map((route, index) => (
                    <Route key={index} path={route.path} exact={route.exact} children={<route.component />} />
                  ))}

                  {/* {privateRoutes.map((route, index) => (
              <PrivateRoute key={index} path={route.path} exact={route.exact} children={<route.component />} />
            ))} */}
                </Layout>
                {/* 404 */}
                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
            </Router>
          </SnackbarProvider>
        </Suspense>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
