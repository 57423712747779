import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

export const useTracking = (trackingId: string) => {
  const { listen } = useHistory();
  ReactGA.initialize(trackingId);

  useEffect(() => {
    const unListen = listen((location) => {
      ReactGA.pageview(location.pathname);
    });
    return unListen;
  }, [trackingId, listen]);
};
