import React from 'react';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

import config from '../../config';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  offset: theme.mixins.toolbar,
  title: {
    flexGrow: 1,
  },
}));

const Header: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <Link component={RouterLink} to="/" color="textPrimary">
            <LocalHospitalIcon className={classes.icon} />
          </Link>
          <Typography variant="h6" color="inherit" noWrap className={classes.title}>
            {config.app.shopName}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </>
  );
};

export default Header;
