import React, { Suspense } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

import Header from './header';
import { useTracking } from '../../components/hooks/use-tracking';
import config from '../../config';
import Loading from '../../components/loading/loading';

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100vw',
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

const BaseLayout: React.FC = ({ children }) => {
  const classes = useStyles();

  useTracking(config.analytics.ga);
  return (
    <>
      <CssBaseline />
      <Header />
      <Suspense fallback={<Loading />}>
        <main className={classes.main}>{children}</main>
      </Suspense>
    </>
  );
};

export default BaseLayout;
