const config = {
  app: {
    shopName: process.env.REACT_APP_SHOP_NAME || 'Shop Name Admin',
    shopId: process.env.REACT_APP_SHOP_ID || 'Shop Name Admin',
    graphQLEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT || 'http://localhost:4003/graphql',
    translationEndpoint: process.env.REACT_APP_TRANSLATION_ENDPOINT || 'http://localhost:3003',
    refreshInterval: 1000 * 60,
  },
  analytics: {
    ga: process.env.REACT_APP_TRACKING_CODE ?? '',
  },
};

export default config;
